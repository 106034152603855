import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../layouts/index';

const QaContent = ({ location: { pathname } }) => (
  <div className="container sub-content" id="qatop">
    <h1>Q&A</h1>
    <div>
      <ul>
        <li>
          <Link to={`${pathname}#change-content`}>
            診断書の内容を書き換えて欲しい
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#bring-to`}>
            当日は何を持っていけばいいの？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#limit`}>健康診断の予約枠を教えてほしい</Link>
        </li>
        <li>
          <Link to={`${pathname}#how-long`}>
            だいたいの所要時間を教えてほしい
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#group-discount`}>
            健康診断には団体割引は適用されますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#period`}>
            月経等で延期になってしまった検査項目はどうしたらよいか
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#how-to-reserve-for-company`}>
            【企業様】予約のとり方を教えてほしい
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#flow-for-company`}>
            【企業様】契約の流れを教えてほしい
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#need-for-company`}>
            【企業様】契約に必要なもの、条件は？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#different-for-company`}>
            【企業様】契約した場合としない場合では何が違うのか
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#forgot`}>
            受診をすっぽかしても大丈夫ですか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#fee-about-results`}>
            結果票、診断書が複数必要なときの料金は？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#film`}>
            レントゲンフィルムはもらえますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#re-publish`}>結果の再発行はできますか？</Link>
        </li>
        <li>
          <Link to={`${pathname}#vaccine`}>ワクチンは同時に打てますか？</Link>
        </li>
        <li>
          <Link to={`${pathname}#reservation`}>
            健康診断でも予約は必要ですか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#irregular`}>異常値について教えてください</Link>
        </li>
        <li>
          <Link to={`${pathname}#insurance-card`}>
            受診の際、保険証は必要ですか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#credit-card`}>
            クレジットカードは使えますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#gastroscope`}>
            胃カメラの際、薬で眠って検査は受けられますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#without-egd`}>
            胃カメラを除いた人間ドック・健診が受けたい
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#when-can-i-get`}>
            結果はどのくらいで出ますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#attention`}>
            前日に注意することはありますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#receive-something`}>
            ドックの予約をした際、事前に何か送られてきますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#barium`}>
            バリウムでの胃検査はできますか？
          </Link>
        </li>
        <li>
          <Link to={`${pathname}#drug-test`}>
            自社の社員に検査内容を知らせずに、尿の薬物検査を受診させることは可能でしょうか？
          </Link>
        </li>
      </ul>
    </div>
    <hr />
    <div className="qa-anker" id="change-content">
      <h3>診断書の内容を書き換えて欲しい</h3>
      <p>
        申し訳ありませんが、書き換えることはできません。
        <br />
        症状や既往歴などを書き間違えたので訂正したい、といった場合にはもちろん訂正いたします。
        <br />
        しかし、検査結果については、虚偽の記載をしますと法令違反・行政処分の対象となる可能性もあり、書き換えることはできません。
        <br />
        ご了承ください。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="bring-to">
      <h3>当日は何を持っていけばいいの？</h3>
      <p>
        検査当日に異常が見つかった場合、保険で追加検査をお勧めすることがございます。
        <br />
        保険証をお持ちであれば、できるだけお持ちいただきますよう、お願いいたします。
        <br />
        また、人間ドック、生活習慣病健診をお受けになる方は事前に受診票、検査キットをお送りいたしますので、当日そちらをお持ちください。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="limit">
      <h3>健康診断の予約枠を教えてほしい</h3>
      <p>
        一般定期健診の予約枠は下記の通りとなっております。
        <br />
        月・水　9：00～12：00（30分区切り）、14：00～17：00（30分区切り）、18：00、19：00
        <br />
        火・金　9：30～12：00、14：00～17：00
        <br />
        木　　　9：00～12：00、14：00～16：30
        <br />
        土　　　9：30～12：00
        <br />
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="how-long">
      <h3>だいたいの所要時間を教えてほしい</h3>
      <p>
        一般定期健診（超音波検査や胃カメラなどを含まない健康診断）は1時間～1時間半程度になります。
        <br />
        人間ドック、生活習慣病健診は3時間～3時間半程度になります。
        <br />
        胃カメラなしの生活習慣病健診は1時間半～2時間程度です。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="group-discount">
      <h3>健康診断には団体割引は適用されますか？</h3>
      <p>
        団体割引は、人間ドックを3名様以上で申込みの場合のみ適用となります。
        <br />
        健康診断等、その他のコースにつきましては、団体割引はございません。
        <br />
        ご了承ください。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="period">
      <h3>月経等で延期になってしまった検査項目はどうしたらよいか</h3>
      <p>
        １か月以内の検査をお願いしております。
        <br />
        未実施の検査項目については、１か月以内のご来院をお願いしております。
        <br />
        一部の検査項目のみのキャンセルも可能です。
        <br />
        なお、便潜血検査は郵送でも対応可能です。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="how-to-reserve-for-company">
      <h3>【企業様】予約のとり方を教えてほしい</h3>
      <p>
        予約方法は２通りございます。
        <br />
        <b>個人予約：</b>
        受診者様ごとに、個別に電話でご予約をおとりします。事前に受診者様全員の名簿をお送りいただく必要がございます。
        <br />
        <b>一括予約：</b>
        あらかじめ希望日時をお伺いいたします。こちらで予約表を作成・FAXいたしますので、そちらにお名前、生年月日等をご記入いただき、ご返送ください。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="flow-for-company">
      <h3>【企業様】契約の流れを教えてほしい</h3>
      <p>
        必要事項を伺い、こちらから契約書を郵送させていただきます。
        <br />
        企業様の所在地や連絡先、健康診断の時期、受診者数、コースなどをお電話でお伺いし、こちらで契約書を作成いたします。
        <br />
        ２部郵送させていただきますので、内容の確認・捺印の上、１部返送いただければ、契約は完了です。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="need-for-company">
      <h3>【企業様】契約に必要なもの、条件は？</h3>
      <p>
        特にございません。
        <br />
        契約に際し、特に必要なもの等はございません。料金が変わることもございません。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="different-for-company">
      <h3>【企業様】契約した場合としない場合では何が違うのか</h3>
      <p>
        請求書払いが可能になります。
        <br />
        契約いただきますと、請求書払いが可能となります。また、個人様宛の健康診断結果とは別に、会社様控えをお送りすることができます。
        <br />
        契約をされない場合は、受診当日にお支払いいただきます。（領収書を会社名でお出しすることは可能です。）
        <br />
        なお、健康診断の予約状況が混み合っている場合、大変勝手ながら契約をお断りさせていただくことがございます。
        <br />
        申し訳ございません。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="forgot">
      <h3>受診をすっぽかしても大丈夫ですか？</h3>
      <p>
        キャンセル料がかかることがありますのでご注意ください。
        <br />
        直前のキャンセルや、受診できない旨のご連絡を頂けなかった場合、キャンセル料が発生してしまうことがあります。
        <br />
        また、人間ドックで団体割引をご利用の場合、他の受診者様の団体割引がなくなることがあります。
        <br />
        忘れずにご受診くださいますよう、お願いいたします。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="fee-about-results">
      <h3>結果票、診断書が複数必要なときの料金は？</h3>
      <p>
        <Link to="/blogs/2013-3-22">
          健康診断の結果票、診断書が複数枚必要な場合の料金については、こちらをご覧ください。
        </Link>
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="film">
      <div>
        <h3>レントゲンフィルムはもらえますか？</h3>
        <p>
          申し訳ありません。フィルムは現像することができません。
          <br />
          当院ではフィルムレスのシステムになっており、フィルムの現像をすることができません。
          <br />
          画像のCD-Rであれば作成することができます。料金は1,500円となっております。
        </p>
        <div>
          <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
        </div>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="re-publish">
      <h3>結果の再発行はできますか？</h3>
      <p>
        はい。料金は500円です。
        <br />
        健康診断の結果表が再度必要になった際は、500円で再発行を承っております。お気軽にお申し出ください。
        <br />
        （検査日の改変等はできませんので、ご了承ください。）
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="vaccine">
      <h3>ワクチンは同時に打てますか？</h3>
      <p>
        はい。ただし、１～２週間前にご連絡いただく必要があります。
        <br />
        健康診断や人間ドックの際、一緒にワクチンを打つこともできます。ただし、事前にワクチンを確保しなければならないため、１～２週間前にはご連絡いただく必要があります。
        <br />
        なお、ワクチンは複数同時接種が可能です。（インフルエンザと風疹ワクチンなど、自由に組み合わせていただけます。）
        <br />
        <a href="http://www.hirahata-clinic.or.jp/menu01/002/" target="_blank">
          ワクチンの料金につきましては、こちらをご覧ください。
        </a>
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="reservation">
      <h3>健康診断でも予約は必要ですか？</h3>
      <p>
        はい。人間ドックだけでなく、健康診断も予約が必要です。
        <br />
        健康診断も予約が必要です。予約枠は随時埋まってしまいますので、お早めにお問い合わせください。
        <br />
        なお、ご予約の際は、メールよりも電話でお申し込みいただきますと、空いている枠などのご案内がしやすく、迅速にご希望の日時に近い予約をお取りすることができます。
        <br />
        1か月以内の受診をご希望の場合は、できるだけお電話で
        お申込みいただきますよう、お願いいたします。
        <br />
        現在、当日のご予約は承っておりませんのでご了承ください。
        <br />
        ＜電話番号：03-6419-7056＞
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="irregular">
      <h3>異常値について教えてください</h3>
      <p>
        健診の異常値については、下記のページをご参照下さい。（随時更新します）
      </p>
      <h4>＜身体所見＞</h4>
      <ul>
        <li>
          <Link to="/blogs/2009-7-27" target="_blank">
            低血圧
          </Link>
        </li>
      </ul>
      <h4>＜尿検査＞</h4>
      <ul>
        <li>
          <Link to="/blogs/2014-4-18" target="_blank">
            尿蛋白陽性
          </Link>
        </li>
        <li>
          <Link to="/blogs/2015-1-13" target="_blank">
            尿潜血陽性
          </Link>
        </li>
      </ul>
      <h4>＜胸部レントゲン＞</h4>
      <ul>
        <li>
          <Link to="/blogs/2011-8-2" target="_blank">
            胸膜癒着
          </Link>
        </li>
      </ul>
      <h4>＜心電図＞</h4>
      <ul>
        <li>
          <Link to="/blogs/2010-7-4" target="_blank">
            平低Ｔ波
          </Link>
        </li>
      </ul>
      <h4>＜腹部エコー＞</h4>
      <ul>
        <li>
          <Link to="/blogs/2014-12-28" target="_blank">
            胆のうポリープ
          </Link>
        </li>
        <li>
          <Link to="/blogs/2013-7-19" target="_blank">
            腎のう胞（腎嚢胞）
          </Link>
        </li>
      </ul>
      <h4>＜胃カメラ＞</h4>
      <ul>
        <li>
          <Link to="/blogs/2010-1-2" target="_blank">
            逆流性食道炎
          </Link>
        </li>
        <li>
          <Link to="/blogs/2011-5-22" target="_blank">
            食道バレット上皮（バレット食道）
          </Link>
        </li>
        <li>
          <Link to="/blogs/2010-1-2_2" target="_blank">
            慢性胃炎
          </Link>
        </li>
        <li>
          <Link to="/blogs/2010-1-10" target="_blank">
            胃腺腫（胃アデノーマ）
          </Link>
        </li>
      </ul>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="insurance-card">
      <h3>受診の際、保険証は必要ですか？</h3>
      <p>
        必要ありませんが、あれば便利です。
        <br />
        特に保険証は必要ありませんが、
        <strong>
          保険証があれば、何か異常が見つかった際にその場で検査や処方が可能
        </strong>
        です。
        <br />
        なお、保険証はコピーではなく、原本をお持ちくださいますようお願いいたします。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="credit-card">
      <h3>クレジットカードは使えますか？</h3>
      <p>
        使えます。（5,000円以上）
        <br />
        オプション料金を含めた総額が5,000円を超える場合は、クレジットカードをご利用いただくことができます。
        <br />
        ご利用いただけるカードは、VISA・MASTER・銀聯・Dinners・JCB・AMEXとなります。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="gastroscope">
      <h3>胃カメラの際、薬で眠って検査は受けられますか？</h3>
      <p>
        オプションでご利用いただけます。
        <br />
        当院は鼻からの胃カメラで検査を施行しておりますので、口からの胃カメラよりは楽に検査が受けられることがほとんどですが、それでもつらい方、不安な方には鎮静剤を使い、眠った状態で検査を受けていただくことも可能です。
        <br />
        その場合、鎮静剤の使用には血圧低下や呼吸抑制などのリスクが伴うため、心電図モニター、自動血圧モニター、酸素飽和度モニターなどで全身状態を確認しながらの検査となります。
        <br />
        なお、大変申し訳ありませんが、鎮静剤の使用には5,300円のオプション料金がかかります。ご了承ください。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="without-egd">
      <h3>胃カメラを除いた人間ドック・健診が受けたい</h3>
      <p>
        胃カメラ検査を除いた人間ドック・健診をお受けいただけます。
        <br />
        <strong>19時まで受診可能</strong>
        ですので、夜しか時間が取れない方にも最適。
        <br />
        詳しくはお問い合わせください。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="when-can-i-get">
      <h3>結果はどのくらいで出ますか？</h3>
      <p>
        通常、2週間程度いただいております。
        <br />
        通常、健診の結果のお渡しまで、２週間程度、お時間をいただいております。一般定期健診はもっと早く結果をお出しすることができますが、オプション料金をいただいております。
        <br />
        生活習慣病健診、人間ドックにつきましては、当日結果をお渡しすることができません。ご了承ください。
      </p>
      <table className="table table-bordered text-center">
        <tbody>
          <tr>
            <td>
              当日結果お渡し（血液検査あり）
              <Link to="/blogs/2012-7-16">詳しくはこちら</Link>
            </td>
            <td>¥3,300</td>
          </tr>
          <tr>
            <td>当日結果お渡し（血液検査なし）</td>
            <td>¥1,000</td>
          </tr>
          <tr>
            <td>4～10日後の指定日(*)</td>
            <td>¥500</td>
          </tr>
        </tbody>
      </table>
      <p>
        (*)血液検査があってもなくても。日・祝日をはさむ時は5日以上かかります。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="attention">
      <h3>前日に注意することはありますか？</h3>
      <p>
        腹部エコー、胃カメラがある方は、前日の夕食は夜９時までにお済ませください。
        <br />
        なお、その際、できるだけ食事は白米、うどん、魚など、消化の良いものだけにして、野菜など海藻類は避けるようにして下さい。飲み物は、少ない量であれば検査前２時間までお飲みいただけますが、麦茶、水などだけにしてください。
        <br />
        牛乳、コーヒー、紅茶、ジュース、などは飲まないでください。
        <br />
        また、腹部エコー時に、膀胱、前立腺なども拝見いたしますので、来院後、できるだけ排尿をされないようお願いいたします。（尿検査は腹部エコーの後にさせていただいております。）
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="receive-something">
      <h3>ドックの予約をした際、事前に何か送られてきますか？</h3>
      <p>
        特に送付物はございません。
        <br />
        日にちに余裕がある場合は、事前にご案内、問診票、子宮頸がん診断キット、検便キット等を送らせていただいております。
        <br />
        一般健診の場合は、特に送付物はございません。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="barium">
      <h3>バリウムでの胃検査はできますか？</h3>
      <p>
        申し訳ありませんが、バリウムでの胃の検査は、当院では施行しておりません。
        <br />
        それは内視鏡の方が精度が高いことが明らかだからです。その代わり、バリウム検査と同程度の金額で検査を施行しております。
        <br />
        <strong>バリウム検査と胃カメラの比較</strong>については以下の通りです。
      </p>
      <ul>
        <li>
          診断精度:バリウム検査は白黒の写真での診断となるため、どうしても診断の精度に限界があります。
          <br />
          内視鏡ならば、カラー画像ですので、胃炎やごく早期のがんなどを、より確実に診断ができます。
          <br />
          さらに、内視鏡ならその場で細胞を取って病理検査に提出するなど、迅速な精密検査が可能です。
        </li>
        <li>
          食道の病気:食道の病気は、基本的にバリウム検査では評価が難しいことがほとんどですが、胃カメラであれば、早期食道がんなどを内視鏡で治療できる段階で検出できます。
        </li>
        <li>
          スピード:バリウム検査では、撮影後、読影という事をしないといけないので、どうしても診断までタイムラグがでてしまいました。
          <br />
          内視鏡では、その場で診断をお伝えすることができます。
        </li>
        <li>
          画像が見られる:ご希望の方は、内視鏡画像を見ながら検査を受けていただけます。
          <br />
          なかなかご自分の胃の中を見る機会は無いため、受診者の方々からは大変ご好評をいただいております。
          <br />
          また、ご希望の方には胃の中の写真を印刷してお渡ししております。もちろん写真は無料です。
        </li>
        <li>
          下剤の有無:バリウム検査では、検査後にバリウムが出るまで下剤を飲んでいただかなくてはなりませんが、内視鏡では下剤を飲む必要はありません。
        </li>
        <li>
          当院で使用している内視鏡当院では富士フィルムメディカル社製のEG-580NW、EG-530NWという最新の胃カメラを使用しており、FICE（分光画像処理機能）システムと合わせて、より正確な診断が可能となっております。
        </li>
      </ul>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
    <hr />
    <div className="qa-anker" id="drug-test">
      <h3>
        自社の社員に検査内容を知らせずに、尿の薬物検査を受診させることは可能でしょうか？
      </h3>
      <p>
        受診することは出来ません。
        <br />
        申し訳ございませんが、受診者本人の同意がなければ薬物検査を受診することは出来ません。
      </p>
      <div>
        <Link to={`${pathname}#qatop`}>ページの先頭へ戻る</Link>
      </div>
    </div>
  </div>
);

const Qa = (props) => (
  <Layout>
    <QaContent {...props} />
  </Layout>
);

export default Qa;
